<template>
  <admin-user-component />
</template>

<script>
import AdminUserComponent from "@/components/admin/AdminUserComponent";

export default {
  name: "AdminUser",
  title: "Gestión de Usuarios | Baja California Health Tourism",
  components: {
    AdminUserComponent,
  },
};
</script>

<style scoped></style>
